import React from 'react';
import {
  LOUP_GAROU,
  VILLAGEOIS,
  SORCIERE,
  VOYANTE,
  CUPIDON,
  CHASSEUR,
  GARDE
} from './utils.js';

import JoinSound from './Sounds/join.mp3';

import { ReactComponent as Icon1 } from './SVG/villagers/1.svg';
import { ReactComponent as Icon2 } from './SVG/villagers/2.svg';
import { ReactComponent as Icon3 } from './SVG/villagers/3.svg';
import { ReactComponent as Icon4 } from './SVG/villagers/4.svg';
import { ReactComponent as Icon5 } from './SVG/villagers/5.svg';
import { ReactComponent as Icon6 } from './SVG/villagers/6.svg';
import { ReactComponent as Icon7 } from './SVG/villagers/7.svg';
import { ReactComponent as Icon8 } from './SVG/villagers/8.svg';

import { ReactComponent as Chasseur } from './SVG/roles/chasseur.svg';
import { ReactComponent as Cupidon } from './SVG/roles/cupidon.svg';
import { ReactComponent as Garde } from './SVG/roles/garde.svg';
import { ReactComponent as Sorciere } from './SVG/roles/sorciere.svg';
import { ReactComponent as Voyante } from './SVG/roles/voyante.svg';
import { ReactComponent as LoupGarou } from './SVG/roles/loupgarou.svg';

import { ReactComponent as BouleCristal } from './SVG/votes/bouleCristal.svg';
import { ReactComponent as Eat } from './SVG/votes/eat.svg';
import { ReactComponent as KillPotion } from './SVG/votes/killPotion.svg';
import { ReactComponent as SavePotion } from './SVG/votes/savePotion.svg';
import { ReactComponent as Shield } from './SVG/votes/shield.svg';
import { ReactComponent as Fleche } from './SVG/votes/fleche.svg';
import { ReactComponent as Fusil } from './SVG/votes/fusil.svg';

export const isProduction = process.env.NODE_ENV === 'production';
export const PORT = 7777;
export const BASE_URL = isProduction
  ? `https://loup-garou.idontcare.fr:${PORT}`
  : `http://192.168.1.31:${PORT}`;

export const SOUNDS = { join: JoinSound };
export const CONF_SNACK = {
  variant: 'success',
  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
  autoHideDuration: 2500
};
export const ROLES = [
  CHASSEUR,
  CUPIDON,
  GARDE,
  SORCIERE,
  VOYANTE,
  LOUP_GAROU,
  VILLAGEOIS
];

export const VILLAGER_ICONS = [
  { svg: <Icon1 className="roleIcon" /> },
  { svg: <Icon2 className="roleIcon" /> },
  { svg: <Icon3 className="roleIcon" /> },
  { svg: <Icon4 className="roleIcon" /> },
  { svg: <Icon5 className="roleIcon" /> },
  { svg: <Icon6 className="roleIcon" /> },
  { svg: <Icon7 className="roleIcon" /> },
  { svg: <Icon8 className="roleIcon" /> }
];
export const ALL_ROLES_ICONS = {
  [CHASSEUR]: <Chasseur className="roleIcon" />,
  [CUPIDON]: <Cupidon className="roleIcon" />,
  [GARDE]: <Garde className="roleIcon" />,
  [SORCIERE]: <Sorciere className="roleIcon" />,
  [VOYANTE]: <Voyante className="roleIcon" />,
  [LOUP_GAROU]: <LoupGarou className="roleIcon" />,
  [VILLAGEOIS]: null
};

export const ALL_VOTES_ICONS = {
  [CHASSEUR]: <Fusil className="voteIcon" />,
  [CUPIDON]: <Fleche className="voteIcon" />,
  [GARDE]: <Shield className="voteIcon" />,
  [SORCIERE]: <KillPotion className="voteIcon" />,
  SAVE: <SavePotion className="voteIcon" />,
  [VOYANTE]: <BouleCristal className="voteIcon" />,
  [LOUP_GAROU]: <Eat className="voteIcon" />,
  [VILLAGEOIS]: null
};

export const COLORS = {
  [CHASSEUR]: 'orange',
  [CUPIDON]: 'pink',
  [GARDE]: 'cornflowerblue',
  [SORCIERE]: 'rebeccapurple',
  [VOYANTE]: 'limegreen',
  [LOUP_GAROU]: 'red',
  [VILLAGEOIS]: 'bisque'
};

export const INFOS_ROLES = {
  [CHASSEUR]:
    "Vous êtes le chasseur, un villageois armé d'un fusil de chasse ! Lorsque vous mourrez, vous pouvez vous venger et emmener la personne de votre choix dans la tombe ! Visez bien, visez juste, tuez tous les loups garous !",
  [CUPIDON]:
    "Vous êtes cupidon, l'angelot de l'amour ! Lors de la première nuit vous allez désigner qui seront les amoureux de la partie. Si l'un meurt l'autre mourra d'un chagrin d'amour. Vous gagnez si tous les loups garous meurent.",
  [GARDE]:
    "Vous êtes le garde/salvateur ! Vous disposez d'un grand pouvoir (qui implique de grandes responsabilités (?)) vous choisissez chaque nuit une personne qui sera alors protégée de l'attaque des loups garous. Vous gagnez si tous les loups garous meurent.",
  [SORCIERE]:
    "Vous êtes la sorcière ! Vous disposez de 2 potions : une qui sauve la vie d'une personne sur le point de mourrir des ses blessures infligées par les loups garous une autre vous permettant de tuer qui vous voulez ! Utilisez-les savament et avec parcimonie. Vous gagnez si tous les loups garous meurent",
  [VOYANTE]:
    "Vous êtes la voyante ! Chaque nuit vous pourrez découvrir la véritable identité d'un villageois. Utilisez votre don pour découvrir où se trouvent les vilains loups ! Vous gagnez si tous les loups garous meurent",
  [LOUP_GAROU]:
    "Vous êtes une abominable créature, le produit malsain d'un esprit dérangé ! Simple villageois le jour, la nuit vous vous transformez en loup garou et vous pouvez avec vos congénères choisir la personne que vous dévorerez vivante. Votre but est de tuer toutes les personnes saines du village",
  [VILLAGEOIS]:
    'Vous êtes un simple villageois, vous devez essayer de déduire qui est loup garou et de tous les tuer lors du vote du village'
};

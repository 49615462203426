import React from 'react';
import { Tooltip } from '@material-ui/core';
import './Player.less';
import {
  VILLAGER_ICONS,
  ALL_ROLES_ICONS,
  ALL_VOTES_ICONS
} from '../../Constantes';
import { ReactComponent as Tombe } from '../../SVG/tombstone.svg';
import { ReactComponent as Pendu } from '../../SVG/votes/pendu.svg';
import { CUPIDON, SORCIERE, LOUP_GAROU, STEP_JOUR } from '../../utils';

const Player = ({
  player,
  me,
  room,
  selected,
  setSelected,
  selectedBis,
  setSelectedBis,
  isVoting = true,
  typeVote = 'jour',
  isVoteLocked
}) => {
  const handleSelect = () => {
    if (isVoting && !isVoteLocked && !player.isDead) {
      const isCupidonRelou = me.role === CUPIDON && room.step === CUPIDON;
      const isSorciereRelou = me.role === SORCIERE && room.step === SORCIERE;

      if (isCupidonRelou) {
        if (selected === null) {
          setSelected(player.id);
        } else if (selectedBis === null) {
          setSelectedBis(player.id);
        } else {
          // Les deux sont renseignés donc on fait un FIFO
          setSelected(selectedBis);
          setSelectedBis(player.id);
        }
      } else if (isSorciereRelou) {
        if (room.todayKilled.includes(player.id)) {
          // Sauve la victime des loups-garous
          if (me.canSave) {
            // Verif popo
            if (selectedBis) {
              setSelectedBis(null);
            } else {
              setSelectedBis(player.id);
            }
          }
        } else {
          // Tue qqn d'autre :
          if (selected === player.id) {
            // déselection de la popo de mort
            setSelected(null);
          } else if (me.canKill && player.id !== me.id) {
            // Verif popo
            setSelected(player.id);
          }
        }
      } else if (player.id !== me.id) {
        if (me.role === LOUP_GAROU && room.step === LOUP_GAROU) {
          // Un loup ne peut pas voter pour un autre loup
          if (player.role !== LOUP_GAROU) {
            setSelected(player.id);
          }
        } else {
          setSelected(player.id);
        }
      }
    }
  };

  const renderVotes = () => {
    if (player.isDead) {
      return <Tombe className="voteIcon" />;
    }
    if (!(room.step === me.role || room.step === STEP_JOUR)) {
      return;
    }
    // Affichage de la victime des LG pour la SORCIERE / l'utilisation de sa popo de vie
    if (
      me.role === SORCIERE &&
      room.step === SORCIERE &&
      room.todayKilled.includes(player.id)
    ) {
      if (selectedBis === player.id) {
        // Sauvé
        return <div>{ALL_VOTES_ICONS['SAVE']}</div>;
      } else {
        // Mangé
        return <div>{ALL_VOTES_ICONS[LOUP_GAROU]}</div>;
      }
    }
    return room.votes
      .filter(v => v.idVote === player.id || v.idVoteBis === player.id)
      .map((v, index) => {
        const votant = room.playerList.filter(p => p.id === v.idVotant)[0];
        return (
          <Tooltip
            title={votant && votant.name}
            placement="right"
            arrow
            key={votant && votant.id}
          >
            <div>
              {typeVote === 'jour' ? (
                <Pendu className="voteIcon" />
              ) : (
                ALL_VOTES_ICONS[me.role]
              )}
            </div>
          </Tooltip>
        );
      });
  };

  const renderPlayerRoleIcon = () => {
    if (
      ((me.trueRolesIds &&
        me.trueRolesIds.length &&
        me.trueRolesIds.includes(player.id)) ||
        player.isDead) &&
      ALL_ROLES_ICONS[player.role] != null
    ) {
      return ALL_ROLES_ICONS[player.role];
    }
    return VILLAGER_ICONS[player.iconIndex].svg;
  };

  const isSelected = selected === player.id || selectedBis === player.id;
  return (
    <div
      className={`player ${isSelected ? 'selected' : ''} ${
        isVoting ? 'isVoting' : ''
      }`}
    >
      <div className="playerName" title={player.name}>
        {player.name}
      </div>
      <div className="iconWrapper" onClick={handleSelect}>
        {renderPlayerRoleIcon()}
      </div>
      <div className="votes">{renderVotes()}</div>
    </div>
  );
};

export default Player;

import React from 'react';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import './Lobby.less';

const Lobby = ({ socket, room, me }) => {
  const startDaGame = () => {
    socket.emit('room.lock', () => {
      toast.warning('Il faut au minimum être 5 pour jouer au loup garou !');
    });
  };

  return (
    <div className="lobby">
      <div className="motd ">
        Cher villageois(e) de Thiercelieux, prenez place et veuillez attendre
        que tous vos voisins viennent vous rejoindre à la place{' '}
        <span className="idRoom">{room.id}</span> du village.
      </div>
      <div className="playerNameList">
        Liste des "villageois" présent(s) :
        {room.playerList.map((p, index) => (
          <div key={index}>- {p.name}</div>
        ))}
      </div>
      {me.isHost && (
        <div>
          <Button
            variant="contained"
            color="primary"
            className="startGame"
            onClick={startDaGame}
            disabled={room.isLocked}
          >
            Commencer la partie
          </Button>
        </div>
      )}
    </div>
  );
};

export default Lobby;

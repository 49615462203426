import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import InfosPlayer from './InfosPlayer/InfosPlayer';
import Player from './Player/Player';
import './Main.less';
import Logs from './Logs/Logs';
import { SORCIERE, CHASSEUR, STEP_JOUR, CUPIDON } from '../utils';

const Main = ({ room, socket, me }) => {
  const [isVoting, setIsVoting] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isVoteLocked, setIsVoteLocked] = useState(false);

  // UNIQUEMENT pour CUPIDON et la SORCIERE !
  const [selectedBis, setSelectedBis] = useState(null);

  // Pour n'ouvrir la modal qu'une seule fois en STEP_JOUR
  const [isModalOpen, setIsModalOpen] = useState(false);

  const lockChoice = () => {
    setIsVoteLocked(true);
    socket.emit('vote.lock');
    setSelected(null);
    setSelectedBis(null);
  };

  useEffect(() => {
    // Reset les votelock quand on change de step !
    if (room && room.step !== room.prevStep) {
      setIsVoteLocked(false);
    }
  }, [room.step, room.prevStep]);

  useEffect(() => {
    setIsVoting(
      (room.step === me.role || room.step === STEP_JOUR) &&
        (!me.isDead ||
          (me.isDead && me.role === CHASSEUR && room.step === CHASSEUR)) // Chasseur vote en étant mort
    );
  }, [room.step, me]);

  useEffect(() => {
    // Ouvre la modal des morts de la nuit / de la pendaison
    if (
      room.step === STEP_JOUR ||
      room.prevStep === STEP_JOUR ||
      room.step === CHASSEUR
    ) {
      setIsModalOpen(true);
    }
  }, [room.step]);

  useEffect(() => {
    if (
      isVoting &&
      !isVoteLocked &&
      (room.step === SORCIERE || selected !== null)
    ) {
      socket.emit('vote.set', selected, selectedBis);
    }
  }, [selected, selectedBis]);

  return (
    <>
      <div className="topBar">
        <div className="roomID">Partie : {room.id}</div>
        <InfosPlayer me={me} />
      </div>
      {/* <div className="currentState">C'est la nuit ! </div> */}
      <div className="mainContent">
        <Logs room={room} />
        <div className="playerList custom-scrollbar">
          {room.playerList.map(p => (
            <Player
              key={p.id}
              player={p}
              room={room}
              socket={socket}
              me={me}
              selected={selected}
              setSelected={setSelected}
              selectedBis={selectedBis}
              setSelectedBis={setSelectedBis}
              isVoting={isVoting}
              typeVote={room.step === STEP_JOUR ? 'jour' : 'local'}
              isVoteLocked={isVoteLocked}
            />
          ))}
        </div>
      </div>

      <div className="actionBar">
        {isVoting &&
        ((room.step !== SORCIERE && selected !== null) ||
          room.step === SORCIERE) && // La sorcière peut ne rien voter
          (room.step !== CUPIDON || selectedBis !== null) && (
            <Button variant="contained" color="secondary" onClick={lockChoice}>
              {room.step === SORCIERE &&
              selected === null &&
              selectedBis === null
                ? 'Ne rien faire'
                : 'Valider'}
            </Button>
          )}
      </div>

      <Dialog
        open={isModalOpen}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setIsModalOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: '#0c0c0d',
            color: 'white',
            border: '1px solid grey'
          }
        }}
      >
        <DialogContent>
          {room.effectivelyKilled.length === 0 ? (
            <div className="titleModal">
              Par chance personne n'est mort cette nuit !
            </div>
          ) : (
            <>
              <div className="titleModal">
                {room.prevStep === STEP_JOUR &&
                  'Le village a éliminé un des leurs'}
                {room.prevStep === CHASSEUR && 'Victime du chasseur :'}
                {room.prevStep !== CHASSEUR &&
                  room.prevStep !== STEP_JOUR &&
                  'Voici les morts de cette funeste nuit :'}
              </div>
              <div className="playerListModal">
                {room.playerList
                  .filter(p => room.effectivelyKilled.includes(p.id))
                  .map(p => (
                    <Player
                      key={p.id}
                      player={p}
                      room={room}
                      socket={socket}
                      me={me}
                      isVoting={false}
                      isVoteLocked={true}
                    />
                  ))}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsModalOpen(false)}
            color="primary"
            variant="contained"
          >
            <div>Ok</div>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Main;

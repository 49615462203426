import React, { useRef, useEffect } from 'react';
import './Logs.less';
import { COLORS, ROLES } from '../../Constantes';

// Prend un text et remplace dedans les role par un text en majuscule et colorisé
export const replaceWords = text => {
  const regexp = new RegExp(`(${ROLES.join('|')})`, 'g');
  // On split sur les mots ["blabla", "cupidon", "blabla"]
  const tab = text.split(regexp);

  // Tous les 2 on entoure le text d'un span !
  for (var i = 1; i < tab.length; i += 2) {
    tab[i] = (
      <span className="colorized" style={{ color: COLORS[tab[i]] }} key={i}>
        {tab[i]}
      </span>
    );
  }
  return tab;
};

const Logs = ({ room }) => {
  const bottomLogs = useRef(null);

  useEffect(() => {
    bottomLogs.current.scrollIntoView({ behavior: 'smooth' });
  }, [room]);

  const printLog = (log, index) => {
    return [
      <div key={`${index}-log`} className={log.isLockVote ? 'voteLock' : ''}>
        {replaceWords(log.text)}
      </div>,
      <hr className="divider" key={`${index}-divider`} />
    ];
  };

  return (
    <div className="logsWrapper custom-scrollbar">
      <div className="logs">
        {room.logs.map((log, index) => printLog(log, index))}
      </div>
      <div ref={bottomLogs} />
    </div>
  );
};

export default Logs;

/**
 * Return the first object which property match the value of the property value given or undefined (if not found)
 * @param {Array} tab
 * @param {String} propValue
 * @param {String} propName
 * @returns the object or undefined (if not found)
 */
const getByProp = (tab, propValue, propName) => {
  if (
    propValue === undefined ||
    propValue === null ||
    tab === undefined ||
    typeof tab !== 'object' ||
    propName === undefined
  )
    return undefined;
  return tab.find(e => {
    if (e.hasOwnProperty(propName)) {
      return e[propName].toString() === propValue.toString();
    }
    return false;
  });
};
/**
 * Return the object that has the specific id or undefined (if not found)
 * @param {Array} tab
 * @param {String} id
 * @returns the object or undefined (if not found)
 */
const getById = (tab, id) => {
  return getByProp(tab, id, 'id');
};

/**
 * Return the first index of the object which property match the value of the property value given
 * @param {Array} tab
 * @param {String} propValue
 * @param {String} propName
 * @returns the index or -1 (if not found)
 */
const getIndexByProp = (tab, propValue, propName) => {
  return tab.findIndex(e => e[propName] === propValue);
};
/**
 * Return the index of the object that has the specific id
 * @param {Array} tab
 * @param {String} id
 * @returns the index or -1 (if not found)
 */
const getIndexById = (tab, id) => {
  return getIndexByProp(tab, id, 'id');
};

/**
 * Fonction random retourne entre : [min,max[
 * --> JAMAIS = MAX ! et valeur pas dans notIn
 * --> Si impossible retourne NULL
 * @param {*} min
 * @param {*} max
 * @param {*} notIn
 */
const rand = (min, max, notIn = []) => {
  // Populate an Array with all possibles values
  let range = [];
  for (let i = min; i < max; i++) {
    // On dégage les valeurs notIn
    if (notIn.indexOf(i) === -1) {
      range.push(i);
    }
  }

  if (range.length > 0) {
    const rangeMax = range.length;
    let r = Math.floor(Math.random() * rangeMax);
    return range[r];
  } else {
    return null;
  }
};

/**
 * Returns a random maj letter
 */
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const randLetter = () => {
  return alphabet[rand(0, 25)];
};

/**
 * Randomize array element order
 */
function shuffle(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const LOUP_GAROU = 'loups garous';
const VILLAGEOIS = 'villageois';
const SORCIERE = 'sorcière';
const VOYANTE = 'voyante';
const CUPIDON = 'cupidon';
const CHASSEUR = 'chasseur';
const GARDE = 'garde';

const STEP_FIN = 'finish';
const STEP_JOUR = 'potence';

module.exports = {
  getById: getById,
  getByProp: getByProp,
  getIndexByProp: getIndexByProp,
  getIndexById: getIndexById,
  rand: rand,
  randLetter: randLetter,
  shuffle: shuffle,
  LOUP_GAROU,
  VILLAGEOIS,
  SORCIERE,
  VOYANTE,
  CUPIDON,
  CHASSEUR,
  GARDE,
  STEP_FIN,
  STEP_JOUR
};

import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { ALL_ROLES_ICONS, INFOS_ROLES, VILLAGER_ICONS } from '../../Constantes';
import { ReactComponent as Tombe } from '../../SVG/tombstone.svg';
import './InfosPlayer.less';
import { replaceWords } from '../Logs/Logs';

const InfosPlayer = ({ me }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <div className="infosPlayerWrapper">
        <div>{me.name}, tu es</div>
        <Button className="infosButton" onClick={() => setIsOpenDialog(true)}>
          {me.isDead ? (
            <div className="iconeLocal">
              <Tombe className="roleIcon" />
            </div>
          ) : (
            <div className="iconeLocal">
              {ALL_ROLES_ICONS[me.role] === null
                ? VILLAGER_ICONS[me.iconIndex].svg
                : ALL_ROLES_ICONS[me.role]}
            </div>
          )}
        </Button>
      </div>
      <Dialog
        open={isOpenDialog}
        TransitionComponent={Slide}
        keepMounted
        onClose={() => setIsOpenDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: '#0c0c0d',
            color: 'white',
            border: '1px solid grey'
          }
        }}
      >
        <DialogContent>
          <div className="titleModal">{replaceWords(me.role || '')}</div>
          <div>{INFOS_ROLES[me.role]}</div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenDialog(false)}
            color="primary"
            variant="contained"
          >
            <div>Compris !</div>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InfosPlayer;

import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import io from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { isProduction, BASE_URL, SOUNDS } from './Constantes';
import RoomInit from './Components/RoomInit';
import Main from './Components/Main';
import Lobby from './Components/Lobby/Lobby';
import './App.less';

const App = () => {
  const [index, setIndex] = useState(0);
  const [socket, setSocket] = useState(null);
  const [me, setMe] = useState({});
  const [room, setRoom] = useState(null);

  const playSound = soundId => {
    console.log('playSound');
    const audio = new Audio(SOUNDS[soundId]);
    audio.play();
  };

  const updateRoom = newRoom => {
    setRoom(newRoom);
    if (newRoom.playerList && newRoom.playerList.length > 0) {
      const newMe = newRoom.playerList.find(p => p.id === socket.id);
      if (newMe) {
        setMe(newMe);
      }
    }
  };

  useEffect(() => {
    if (isProduction) {
      window.addEventListener('beforeunload', onLeavePage);
    }
  }, []);

  const onLeavePage = e => {
    e.returnValue = 'useless';
    return 'useless';
  };

  useEffect(() => {
    if (socket != null) {
      // Ici on gère les évenement reçu du server
      socket.on('playSound', playSound);
      socket.on('room.update', updateRoom);
      socket.on('disconnect', reason => {
        console.log('MAYDAY MAYDAY SERVER LOST : ' + reason);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (room) {
      let newIndex = 0;
      switch (room.status) {
        case 'lobby':
          newIndex = 1;
          break;
        case 'game':
          newIndex = 2;
          break;
        default:
          newIndex = 0;
      }
      if (newIndex !== index) {
        setIndex(newIndex);
      }
    }
  }, [room]);

  useEffect(() => {
    // Empêche le menu contextuel sur mobile au longPress
    // and btw le click droit aussi donc que en prod
    if (isProduction) {
      window.oncontextmenu = function(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }

    // Créer la connection au serveur set le socket !
    const socketInit = io.connect(BASE_URL, { secure: isProduction });
    console.log('Connection en mode : ', isProduction ? 'Production' : 'dev');
    setSocket(socketInit);
  }, []);

  return (
    <>
      <SwipeableViews resistance index={index} disabled className="swipViews">
        <div className="fullScreen">
          <RoomInit socket={socket} />
        </div>
        <div className="fullScreen">
          {room != null && <Lobby room={room} socket={socket} me={me} />}
        </div>
        <div className="fullScreen main">
          {room != null && <Main room={room} socket={socket} me={me} />}
        </div>
        <div>WHAT</div>
      </SwipeableViews>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;

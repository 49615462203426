import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import NoSleep from 'nosleep.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ReactComponent as Logo } from '../SVG/roles/loupgarou.svg';

import './RoomInit.less';

const RoomInit = ({ socket }) => {
  const [name, setName] = useState(localStorage.getItem('pseudo') || '');
  const [trigramme, setTrigramme] = useState('');
  const [errorJoin, setErrorJoin] = useState(false);
  const [hasEverBeenClicked, setHasEverBeenClicked] = useState(false);
  const [isOpenDialog, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const trigrammeInput = useRef(null);
  const pseudoInput = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeName = e => {
    setName(e.target.value);
    localStorage.setItem('pseudo', e.target.value);
  };

  const handleChangeTrigramme = e => {
    setTrigramme(e.target.value.toUpperCase());
  };

  const handleOpenModal = () => {
    setHasEverBeenClicked(true);
    if (name.length !== 0) {
      setTrigramme('');
      setIsOpen(true);
    } else {
      pseudoInput.current.focus();
    }
  };

  const handleCreateRoom = () => {
    setHasEverBeenClicked(true);
    if (name.length !== 0) {
      activateNoSleep();
      socket.emit('room.create', name, retour => {
        if (!retour.isOk) {
          toast.error('Erreur : (never trigger) probleme serveur');
        }
      });
    } else {
      pseudoInput.current.focus();
    }
  };

  useEffect(() => {
    if (isOpenDialog) {
      trigrammeInput.current.focus();
    }
  }, [isOpenDialog]);

  const handleSubmitByEnter = e => {
    e.preventDefault();
    handleJoinRoom();
  };

  const activateNoSleep = () => {
    var noSleep = new NoSleep();
    noSleep.enable();
  };

  const handleJoinRoom = () => {
    if (trigramme.length === 3) {
      setIsLoading(true);
      setErrorJoin(false);
      activateNoSleep();
      socket.emit('room.join', trigramme, name, retour => {
        setIsLoading(false);
        if (retour.isOk) {
          setIsOpen(false);
        } else {
          toast.error('Erreur : ' + retour.error);
          setErrorJoin(true);
          trigrammeInput.current.focus();
          trigrammeInput.current.select();
        }
      });
    } else {
      toast.error('Un identifiant de partie est composé de 3 lettres !');
    }
  };

  return (
    <div className="roomInit">
      <div className="logoWrapper">
        <Logo className="logo" />
      </div>
      <div className="title">Loup-Garou</div>
      <form
        className="roomForm"
        noValidate
        autoComplete="off"
        onSubmit={e => e.preventDefault()}
      >
        <TextField
          label="Pseudo"
          variant="outlined"
          value={name}
          inputRef={pseudoInput}
          onChange={handleChangeName}
          error={name.length === 0 && hasEverBeenClicked}
        />
        <div className="buttons-wrapper">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenModal}
          >
            Rejoindre
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateRoom}
          >
            Créer
          </Button>
        </div>
      </form>
      <div className="version">
        Version <span className="big">α</span>
      </div>
      <Dialog
        open={isOpenDialog}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>Identifiant de la partie ?</DialogTitle>
        <DialogContent>
          <form
            className="TrigrammeForm"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmitByEnter}
          >
            <TextField
              autoFocus
              variant="outlined"
              value={trigramme}
              onChange={handleChangeTrigramme}
              inputRef={trigrammeInput}
              inputProps={{ maxLength: 3 }}
              error={errorJoin}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleJoinRoom}
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} className="loaderJoinRoom" />
            ) : (
              <div>Go</div>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RoomInit;
